import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, NumberInput, BooleanInput, FormDataConsumer,
} from 'react-admin';
import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import {FloatNumber} from "../../_common/CompFormat/utils";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useMemo} from "react";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {discount_rule_id, rule_type, slug} = props;
    const translate = useTranslate();

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    /*
    "id": 0,
      "product_id": 0,
      "order_quantity": 0,
      -"created_at": "2023-08-29T10:49:57.073Z",
      -"updated_at": "2023-08-29T10:49:57.073Z",
      -"discount_rule_id": 0,
      "discount": 0,
      "use_fix_price": true,
      "fix_price": 0,
      "fix_price_currency_id": 0
    */

    return (
        <>
            <RowForm
                {...props}
                initialValues={{discount_rule_id: discount_rule_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>

                <ReferenceInput source="product_id" reference="product_z"
                                label={translate('Product')}
                                validate={validateReq}>
                    <AutocompleteInput
                        resettable
                        optionText={useMemo(() => {
                            return ((record) => `${record?.article} - ${record?.name}`)
                        }, [])}
                        // optionText={optionText}
                        // optionText="name"
                        fullWidth
                        /*???filterOptions={(option, state) => {//Берет, все что пришло, не применяя фильтр
                            return option
                        }}*/
                    />
                </ReferenceInput>
                {(rule_type === 'order' || rule_type === 'bonus')
                    && slug !== 'order.price_of_week' && slug !== 'order.discount_from_sum_of_order' &&
                    <NumberInput source={'order_quantity'} label={translate('Quantity')}
                                 validate={validateReq} style={{maxWidth: 80, minWidth: 80}}
                    />
                }
                {rule_type === 'bonus' &&
                    <ReferenceInput source="gift_pool_id" reference="gift_pool"
                                    label={translate('Bonuses')}
                                    validate={validateReq}>
                        <AutocompleteInput resettable optionText="name" fullWidth/>
                    </ReferenceInput>
                }
                {(rule_type !== 'bonus' || slug === 'order.price_of_week') &&
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (!formData.use_fix_price)
                                return (
                                    <NumberInput source={'discount'} label={translate('Discount')}
                                                 style={{minWidth: 90, maxWidth: 90}}
                                                 validate={validateReq}/>
                                )
                            else return null
                        }}
                    </FormDataConsumer>
                }
                {rule_type !== 'bonus' && slug !== 'order.price_of_week' &&
                    <BooleanInput source="use_fix_price" label={''} defaultValue={false}/>
                }
                {rule_type !== 'bonus' && slug !== 'order.price_of_week' &&
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData.use_fix_price)
                                return (
                                    <div style={{display: "grid", margin: -8}}>
                                        <NumberInputFloatComp source="fix_price" label={translate('Fix Price')}
                                                              validate={validateReq}
                                                              style={{maxWidth: 160}}
                                        />
                                        <div
                                            style={{marginTop: -32, marginBottom: -18}}
                                        >
                                            <ReferenceInput source="fix_price_currency_id" reference="currency_z"
                                                            defaultValue={980}
                                                            label={translate('Currency')}
                                                            validate={validateReq}
                                                            fullWidth
                                            >
                                                <AutocompleteInput resettable optionText="name" fullWidth/>
                                            </ReferenceInput>
                                        </div>
                                    </div>
                                )
                            else return null
                        }}
                    </FormDataConsumer>
                }
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes, rule_type, slug) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        product_id:
            <ReferenceField source="product_id" reference="product_z" label={translate('Product')}
                            link={true}
                            cellClassName={classes.long_name}
                            sortBy={'product_z:name'}
            >
                <div style={{display: "grid"}}>
                    <TextField source="article" style={{fontWeight: "bold"}}/>
                    <TextField source="name"/>
                </div>
            </ReferenceField>,
        order_quantity: <NumberField source="order_quantity" label={translate('Order Quantity')}/>,
        gift_pool: <ReferenceField source="gift_pool_id" reference="gift_pool" label={translate('Bonuses')}
                                   link={true}
                                   sortBy={'gift_pool:name'}>
            <TextField source="name"/>
        </ReferenceField>,
        discount: <NumberField source="discount" label={translate('Discount')}/>,
        use_fix_price: <BooleanFieldComp source="use_fix_price" label={translate("Use Fix Price")}
                                         textAlign={"center"}/>,
        fix_price:
            <div label={'Fix Price'}>
                <FloatNumber source={`fix_price`} label={translate('fix_price')}/>{' '}
                <ReferenceField source="fix_price_currency_id" reference="currency_z"
                                label={translate('Fix Price Currency')}
                                link={false}
                                sortBy={'currency_z:name'}>
                    <TextField source="name"/>
                </ReferenceField>
            </div>,

        /*created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,*/
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }


    if (slug === 'order.discount_from_sum_of_order') {
        delete columns.order_quantity
    }
    if (slug === 'order.price_of_week') {
        delete columns.order_quantity
        delete columns.use_fix_price
        delete columns.fix_price
        delete columns.fix_price_currency_id
        delete columns.gift_pool
    }
    if (rule_type === 'bonus') {
        delete columns.discount
        delete columns.use_fix_price
        delete columns.fix_price
        delete columns.fix_price_currency_id
    } else if (rule_type !== 'order') {
        delete columns.order_quantity
        delete columns.gift_pool
    } else delete columns.gift_pool

    return columns;
};

const ProductsByRule = props => {
    // const {customer_id, record, isSave = true} = props;
    const {discount_rule_id, rule_type, slug} = props;

    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    console.log('slug=======>', slug);

    const columns = useSelectedColumns({
        preferences: `${'products_bu_rule'}_${rule_type}_${slug}list.columns`,
        columns: ColumnsForList('products_bu_rule', classes, rule_type, slug),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Product Discount By Rule"
                reference="product_discount_by_rule"
                target="discount_rule_id"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} rule_type={rule_type} slug={slug}
                                              discount_rule_id={discount_rule_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} rule_type={rule_type} slug={slug}
                                            discount_rule_id={discount_rule_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ProductsByRule;