import * as React from 'react';
import {
    TextInput,
    TextField,
    ReferenceManyField,
    // NumberField,
    useTranslate, useLocale,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    // BooleanInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
    // CreateButton,
    // EditRowButton,
    // DeleteRowButton,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp, getDt, NumberInputFloatComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
// import {validateReq} from "../utils/components/validateTable";
import {Box} from "@material-ui/core";
import {useMemo} from "react";
import {validateReq} from "../../_constants/validateTable";

const transform = (data) => {

    data.source = data.source2;
    delete data.source2;

    data.ext_document_id = data.ext_document_id2;
    delete data.ext_document_id2;

    return data;
}
const ListEditForm = (props) => {
    // const classes = useMyStyles();
    const {related_id, record} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    promotion: related_id,
                    source2: localStorage.getItem('username'),
                    ext_document_id2: record.ext_document_id ? Number(record.ext_document_id) : null,
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >

                <ReferenceInput
                    style={{marginBottom: -24}}
                    label={translate('Discount Rule')}
                    source={'ext_document_id2'}
                    reference={'discount_rules'}
                    // variant={"outlined"}
                    filter={{active: 'true', rule_type: "order,bonus"}}
                    validate={validateReq}
                >
                    <AutocompleteInput
                        optionText={useMemo(() => {
                            return ((record) => `${record?.id}. ${record?.name}`)
                        }, [])}
                        resettable
                        fullWidth
                    />
                </ReferenceInput>

            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {

        ext_document_id:
            <ReferenceField
                label={translate('Discount Rule')}
                source={'ext_document_id'}
                reference={'discount_rules'}
                link={false}
            >
                <Box>
                    <TextField source={`id`}/>{'. '}<TextField source={`name`}/>
                </Box>
            </ReferenceField>,

        source:
            <Box display={'grid'} label={translate('Updated')}>
                <CroppedLongField source="source" long={8} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                    /*cellClassName={classes.date_st}*/ showTime={true}/>

            </Box>,
    }
    return columns;
};

const PromotionalGood = props => {
    // const {customer_id, record, isSave = true} = props;
    const translate = useTranslate();
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'PromotionalGood'}relaited.columns`,
        columns: ColumnsForList('dPromotionalGood', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label={translate('Discount Rule')}
                reference="dPromotionDiscountRule"
                target="promotion"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                    /*actions={
                        <>
                            <EditRowButton/>
                            <DeleteRowButton/>
                        </>
                    }*/
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default PromotionalGood;