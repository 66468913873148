import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    //useLocale,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp, ImageInputComp} from "../../_common/CompReact";

//import {validateReq} from "../utils/components/validateTable";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const translate = useTranslate();
    //const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"} mb={'16px'}>
                <Box
                    style={{border: '1px solid #ddd', borderRadius: 16}}
                    display="flex"
                    alignItems={"top"}
                    flex={5} mr={"1em"}
                >
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image -'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image</b> here (500kb max)</p>}
                    />
                </Box>
                <Box alignItems={"top"} flex={5}>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            // prompt={promptData.find(x => x.field === 'name')}
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'rgb_code'}
                            label={translate('RGB code')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'rgb_code_name'}
                            label={translate('RGB badge name code')}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
export const PromotionBadgeEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default PromotionBadgeEditCreate
